<template>
    <div class="main box">
        <h1>Career</h1>
        <posts-table cat="5"></posts-table>
    </div>
</template>

<script>

import PostsTable  from "./../components/PostsTable.vue";

export default {
    name : "Career",
    components : { PostsTable },

}
</script>

<style lang="scss" scoped>
  h1 {
    color:#fff;
    text-align: center;
    font-size:25px;
    text-transform: uppercase;
    font-weight: 300;
    margin:40px auto;
  }
</style>